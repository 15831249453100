<template>
  <div class="pages">
    <div class="header">
      <img src="../assets/images/pay-error.png" alt="" class="pay-error" />
      <div class="status-text">人脸识别验证未成功</div>
    </div>
    <div class="opt">
      <button class="btn next-btn center float-ani" @click="startFace">继续人脸识别认证</button>
      <button class="btn cancel-btn center" @click="cancelFace">退出验证</button>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { Toast } from 'vant'
  import { useRouter } from 'vue-router'
  import wx from "weixin-js-sdk"
  export default {
    name: 'faceerr',
    setup() {
      const router = useRouter();
      const state = reactive({
        merId: null,    //商户id
        channelCode: 'LKL',  //渠道
        type: 0,      //0当前法人 1分享给法人
        
      })
      onMounted(async () => {
        state.merId = router.currentRoute._rawValue.query.merid||null;
        state.channelCode = router.currentRoute._rawValue.query.channelcode||'LKL';
        state.type = router.currentRoute._rawValue.query.type||0;
        
      })
      
      const startFace = () => {
        router.push({
          name: "face",
          query:{
            merid: state.merId,
            channelcode: state.channelCode,
            type: state.type
          }
        });
      }
      
      const cancelFace = () => {
        // router.push({
        //   name: "index"
        // });
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            // 小程序环境下逻辑
            console.log("小程序环境下");
            wx.miniProgram.reLaunch({
              url:'/pages/index/index'
            });
          } else {
            //非小程序环境下逻辑
            console.log("非小程序环境下");
            router.push({name: "index"});
          }
        });     
      }
  
      return {
        ...toRefs(state),
        startFace,
        cancelFace
      }
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/style/mixin';
  
  body{
    background-color: #ffffff;
  }
  .pages{
    padding: 10px 0;
    display:flex;
    flex-direction: column;
  }
  .header{
    text-align: center;
  }
  .pay-error{
    margin-top: 32.5px;
    width: 53px;
    height: 53px;
    margin-bottom: 17.5px;
  }
  .status-text{
    font-size: 18px;
    font-weight: bold;
    color: #FA3474;
  }
  .opt{
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 30px;

  }
  .next-btn{
    width: 345px!important;
    padding: 0!important;
    height: 44px;
    background: #FA3474;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    border: 1px solid #FA3474;
    margin-bottom: 15px;
  }
  .cancel-btn{
    width: 345px!important;
    padding: 0!important;
    height: 42px;
    background: #fff;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    border: 1px solid #ddd;
    margin-bottom: env(safe-area-inset-bottom);
  }
</style>